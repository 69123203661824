import React from "react"
import StickerCard from "./stickerCard"
import "./storefront.scss"

const StoreFront = ({ data }) => {
  return (
    <div className="store_wrap layouted">
      <div className="full-bleed o_bg"></div>
      <div className="hero">
        <h1>Sticker Store</h1>
        <h2>
            Use product badges and labels to supercharge your product catalog in
            minutes & simplify your marketing.
        </h2>
      </div>
      <div className="rel_p main">
        {data.map(rp => (
          <StickerCard data={rp} />
        ))}
      </div>
    </div>
  )
}

export default StoreFront
