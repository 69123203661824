import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import StoreFront from "../components/storefront"

const IndexPage = ({ data }) => {
  const cmsdata = data.allSanitySticker.nodes
  return (
    <Layout>
      <Seo title="Home" />
      <StoreFront data={cmsdata} />
      {/* <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    /> */}
    </Layout>
  )
}

export const query = graphql`
  {
    allSanitySticker {
      nodes {
        title
        price
        tags
        slug {
          current
        }
        id
        images {
          asset {
            url
          }
        }
      }
    }
  }
`

export default IndexPage
